<template>
  <div ref="content" v-html="content"></div>
</template>

<script>
import MarketingSiteProxy from "@/common/marketing-site-proxy";
import {RH_MARKETING_URL} from "@/common";
import {userInfo} from "@/common/user-info";

export default {
  data() {
    return {
      content: ''
    };
  },
  async created() {
    await this.fetchContent(this.$route.path);
  },
  watch: {
    async $route(to, from) {
      await this.fetchContent(to.path);
    }
  },
  methods: {
    async fetchContent(path) {
      // --- If user is logged in, do not fetch the marketing site content
      if (userInfo() && userInfo().user_id) {
        return;
      }
      const cleanedPath = path.replace('/home/', ''); // Adjust the path as needed
      const host = window.location.hostname;
      const port = window.location.port;
      const protocol = window.location.protocol;
      this.content = await MarketingSiteProxy.pullFromMarketing(cleanedPath);
      this.content = this.replaceStringInContent(this.content, "<main>", '<main class="proxied">');
      this.content = this.replaceStringInContent(this.content, "log_in", protocol + "//" + host + ":" + port + "/login");
      this.$nextTick(() => {
        this.loadScripts();
        this.setupLinkInterception();
      });
    },
    loadScripts() {
      const contentElement = this.$refs.content;
      const scripts = contentElement.querySelectorAll('script');
      scripts.forEach(script => {
        const newScript = document.createElement('script');
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.body.appendChild(newScript);
      });
    },
    replaceStringInContent(content, search, replace) {
      const regex = new RegExp(search, 'g'); // 'g' for global search
      return content.replace(regex, replace);
    },
    setupLinkInterception() {
      const contentElement = this.$refs.content;
      const links = contentElement.querySelectorAll('a[href^="/home/"]');
      links.forEach(link => {
        link.addEventListener('click', this.handleLinkClick);
      });
    },
    handleLinkClick(event) {
      event.preventDefault();
      const path = event.currentTarget.getAttribute('href');
      this.$router.push(path);
    }
  },
  beforeDestroy() {
    const contentElement = this.$refs.content;
    if (contentElement) {
      const links = contentElement.querySelectorAll('a[href^="/home/"]');
      links.forEach(link => {
        link.removeEventListener('click', this.handleLinkClick);
      });
    }
  }
};
</script>

/* Your scoped styles here */
<style>
.proxied {
  max-width: 100%;
}
</style>

