<template>
  <div>
    <main class="gate login">
      <div class="together">
        <img src="/rallyhood-white.svg" />
        Together with purpose!
      </div>
      <div class="login-form">
        <form @submit.prevent="handleSubmit" ref="loginform">

          <p v-if="status.error" style="color:red;text-align:center">Login Error Occured</p>
          <p v-if="!status.error" style="color:white;text-align:center">-&nbsp;</p>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input v-model="username" id="email" name="email" type="text" autocomplete="username" @click="scrollTop"/>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="password-input-container">
              <input v-model="password" :type="showPassword ? 'text' : 'password'" id="password" name="password" autocomplete="current-password" class="password-input"/>
              <a href="#" class="toggle-password" @click.prevent="togglePassword">
                {{ showPassword ? 'Hide' : 'Show' }}
              </a>
            </div>
          </div>
          <div class="actions">
            <button :disabled="false" :class="!status.loggingIn ? 'primary' : ''" type="submit" style="margin-bottom:15px;">
              Log In
            </button>
            <br/>
            <router-link class="link-button" to="/createAccount">Create Account</router-link>
            &nbsp;
            <router-link class="link-button" to="/resetpasswordemail">Reset Password</router-link>
            &nbsp;
            <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
          </div>
        </form>
      </div>
      <!--<hr />
      <div class="trouble">
        <div>
          Having trouble logging in?
        </div>
        <div>
          <router-link class="link-button" to="/resetpasswordemail">Reset Password</router-link>
          or
          <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
        </div>
      </div>-->
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { router, RH_URL } from "../common";
import {Capacitor, Plugins} from "@capacitor/core";
const { PushNotifications, LocalNotifications } = Plugins;
import {userInfoPersistent} from "@/common/user-info";

import GateMixin from "@/components/GateMixin.vue";

const { Storage } = Plugins;
const { App } = Plugins
const { Browser } = Plugins;
export default {
  name: "LoginPage",
  mixins: [GateMixin],
  data() {
    return {
      username: "",
      password: "",
      //submitted: false,
      // status: { loggingIn: false },
      msg_token: "",
      showPassword: false,
      screenWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState("user", ["status", "user"])
  },
  watch: {
    user: function(newval, oldval) {
      if (this.invitationToken) {
        const rallyId = this.$route.query.rallyId;
        console.log("GOT RALLY ID: " + rallyId);
        console.log(this.$route.query);
        this.$router.push({
          path: `/${rallyId}/igate`,
          query: { ...this.$route.query }
        });
      } else if (this.$route.query.redirect){
        this.$router.push({
          path: this.$route.query.redirect
        });
      } else {
        //when user loads then proceed to rallies
        router.push("/rallies");
      }
    }
  },
  async created() {
    // reset login status
    console.log("calling created!!!")
    const url = new URL(window.location.href);
    // Get the query parameters
    const params = new URLSearchParams(url.search);
    const pwtoken = params.get('prt'); // "2"
    if (pwtoken){
      //prt=rZsYx6PtHtvxisr8CJT5/1/010001917a81b531-aa6e6457-bb27-4874-ac94-9ace5c19313d-000000/Gj4SIG1uKdwdpf_A_6ACXCBZL44=388
      router.push("/resetpassword?prt=" + pwtoken.split('/')[0]);
    }
    // Access specific parameters
  
    this.setupListener()
    let user = await userInfoPersistent()
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      router.push("/rallies");
    } else {
      this.logout();
    }
  },
  mounted() {
    // Add a window resize event listener when the component is mounted
    this.username = this.email || "";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions("user", ["login", "registerUserDevice","loadPushStuff"]),
    createAccount() {
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("at");
      localStorage.removeItem("user");
      await Storage.remove({ key: 'user' });
    },
    async setupListener() {
      //console.log("setupListener!!!!!")
      Capacitor.Plugins.App.addListener('appStateChange', ({ isActive }) => async function() {
        if (!isActive) {
          // App went to background
          // Save anything you fear might be lost
          console.log("APP INACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        } else {
          // App went to foreground
          // restart things like sound playing
          let user = await userInfoPersistent()
          localStorage.setItem("user", JSON.stringify(user));
          console.log("APP ACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
          this.backToDashboard()
        }
      });

      Capacitor.Plugins.App.addListener('appUrlOpen', function (event) {
        //https://staging.rallyhood.com/users/password/edit?reset_password_token=URyLTAhz8y6iyh-SJS8v
        //https://rallyhood.com/users/passwords/reset_ras?prt=jp1Y2T3q4NrTjTn2haBr
        try{
        console.log("appurlopen!!!")
        const url = new URL(event.url);
        console.log(event.url)
        const slug1 = event.url.split('rallyhood.com/').pop();
        const params = new URLSearchParams(url.search);
        const pwtoken = params.get('prt'); 
        if (!pwtoken){
          router.push("/rallies")
          setTimeout( function() {
            router.push(slug1)
          }.bind(this), 4000);
        } else {
          router.push("/resetpassword?prt=" + pwtoken.split('/')[0]);
        }

          /*console.log("deeplink: " + slug1)
          if (slug1 && slug1.indexOf("users/password") >= 0){
              Browser.open({ url: (RH_URL() + slug1 + "&cap_open=1").replace("\\", "") });
              Browser.addListener('browserFinished', () => {
              console.log('browserFinished event called')
              location.reload()
            });
          } else if (slug1 && slug1.indexOf("channel_key") >= 0){
              Browser.open({ url: (RH_URL() + slug1 + "&cap_open=1").replace("\\", "") });
              Browser.addListener('browserFinished', () => {
              console.log('browserFinished event called')
              location.reload()
            });
          }
          else if (slug1) {
            setTimeout( function() {
                if (slug1.indexOf("et=") >= 0 ){//https://rallyhood.com/35706/fundraisers/16032584?et=amFzb24udG9wbGlmZkByYWxseWhvb2QuY29t&ut=VXNlcjM0NzIxNg%3D%3D
                  Browser.open({ url: (RH_URL() + slug1 + "&cap_open=1").replace("\\", "") });
                } else if (slug1.indexOf("channel_key") >= 0){
                  //https://rallyhood.com/42081/fundraisers/15764388?channel_key=f5d60fa5e1cebeb739d3469d0c9f9d69
                  let slug2 = slug1.split("?")[0]
                  slug2 = slug2.replace("fundraisers", "special_events")
                  console.log("routing to slug " + slug2)
                  router.push(slug2)
                }else {
                  router.push(slug1) 
                }
              
            }.bind(this), 4000);
          }*/
        } catch (e){
          console.log("deeplink: error")
          console.log(e)
          try{
          router.push(`/rallies`)
          } catch (e3){
            router.push(`/login`)
          }
        }
      });
      try{
      console.log("loading push stuff in login page!!!")
      let user = await userInfoPersistent()
      this.loadPushStuff({ user });
      } catch (e){
        console.log(e)
        console.log("error loading push stuff in login page")
      }
      console.log("finished loading push stuff in login page")
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    handleSubmit(e) {
      const { username, password } = this;
      this.$gtag.event("login", { method: "Google" });
      if (username && password) {
        this.login({ username: username.trim(), password: password.trim() });
      }
      this.scrollBack()
    },
    scrollBack(){
      this.$refs["loginform"].style = ''
    },
    scrollTop(){
      if (this.screenWidth < 960) {
      this.$refs["loginform"].style = 'position: fixed; top: 25px; width: 100%'
      }
    },
    scrollToTop () {
      this.$el.scrollTop = 0
    }
  }
};
</script>
<style scoped>
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  flex-grow: 1;
  padding-right: 60px; /* Adjust as needed for space for the toggle link */
}

.toggle-password {
  color: #008295;
  position: absolute;
  right: 10px; /* Adjust as needed for exact positioning */
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
}
</style>
