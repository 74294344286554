import {Plugins} from "@capacitor/core";
import jwt_decode from "jwt-decode";
import { store } from "../store";
const { Storage } = Plugins;
import  { refresh_token }  from "../common/jwt-interceptor"
import  { setRefreshToken, setAccessToken }  from "../common/auth-header"

export function userInfo() {
    try {
        let userInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        return userInfo;
    } catch (e){
        return null
    }
}

export async function syncTimezone() {
    try {
            console.log("checking sync timezone")
            if (localStorage.getItem("mat")) {
                console.log("found mat, not syncing")
                return; 
            }
            let localUser = JSON.parse(localStorage.getItem("user"));
            let storageUser = await Storage.get({ key: 'user' })
            storageUser = JSON.parse(storageUser.value)
            let access_token = localStorage.getItem("at");
            let decodedToken = jwt_decode(access_token);
            let usersById = store.getters["user/usersById"];
            let dbtz = usersById[localUser.user_id]?.attributes["time-zone"]
            console.log("timezone... lu:" + localUser.timezone + " --- at:" + decodedToken.user.tz + " --- ubid:" + usersById[localUser.user_id]?.attributes["time-zone"])
            if (!dbtz || !decodedToken.user.tz || localUser.timezone != dbtz || storageUser.timezone != dbtz || localUser.timezone != decodedToken.user.tz || storageUser.timezone != decodedToken.user.tz) {
                console.log("syncing timezone")
                //store.dispatch('user/flashWarning', 'Syncing timezone.');
                try {
                await store.dispatch('user/fetchUsersById', {userIds: [localUser.user_id], loggedInUser: true})
                } catch (e){
                    console.log("error in fetch")
                    console.log(e)
                }
                usersById = store.getters["user/usersById"];
                dbtz = usersById[localUser.user_id]?.attributes["time-zone"]
                let rtokenres = null
                try { 
                    rtokenres = await refresh_token();
                } catch(e){
                    console.log("refresh error")
                    console.log(e)
                }
                console.log("got refresh token")
                if(rtokenres.data.access_token) {
                    await setAccessToken(rtokenres.data.access_token);
                    await setRefreshToken(rtokenres.data.refresh_token);
                }
                //fetch these again to get the lastest refresh token
                localUser = JSON.parse(localStorage.getItem("user"));
                storageUser = await Storage.get({ key: 'user' })
                storageUser = JSON.parse(storageUser.value)
                localUser.timezone =  dbtz
                storageUser.timezone = dbtz
                localStorage.setItem("user", JSON.stringify(localUser));
                await Storage.set({
                    key: "user",
                    value: JSON.stringify(storageUser)
                });
                console.log("setting user tz: ")
                console.log(localUser)
                console.log(storageUser)
            }
    } catch (e){
        console.log(e)
    }
}

export async function userInfoPersistent() {
    try {
        let user = await Storage.get({ key: 'user' });
        let parsedUser = JSON.parse(user.value);
        return parsedUser;
    } catch (e){
        console.log("ERRROR!!!!")
        console.log(e)
        return null 
    }
}