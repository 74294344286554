<template>
  <div class="event-fields">
    <div class="form-group checkboxes inline-labels">
      <label v-if="optional">
        <input
          type="checkbox"
          v-model="enabled"
          @click="toggleFields"
        />
        <div class="square" />
        Include Date
      </label>
      <label v-if="display">
        <input
          type="checkbox"
          v-model="content.attributes['all-day']"
        />
        <div class="square" />
        All Day
      </label>
    </div>
    <div
      v-if="display"
      :class="[{'dates-only': allDay}, 'form-group', 'dates-and-times']"
    >
      <div>
        <div class="form-group md-6">
          <label>Starting Date</label>
          <datepicker   :format="customFormatterDate"   class="form-control input-lg timepicker"
                        v-model="fromDateString" @selected="dateFromChangeHandler"  name="uniquename"></datepicker>
        </div>
        <div class="form-group md-6 time">
          <label>Starting Time</label>
          <vue-timepicker class="form-control input-lg" format="hh:mm A"  :minute-interval="15"   v-model="fromTime"  @change="timeChangeHandler($event, 'from-time')" ></vue-timepicker>
        </div>
      </div>
      <div>
        <div class="form-group md-6">
          <label>Ending Date</label>
          <datepicker  :format="customFormatterDate"  @selected="dateToChangeHandler" v-model="toDateString"  class="form-control input-lg timepicker" name="uniquename2"></datepicker>
          </div>
        <div class="form-group md-6 time">
          <label>Ending Time</label>
          <vue-timepicker format="hh:mm A"  :minute-interval="15" :value="toTime"  @change="timeChangeHandler($event, 'to-time') " ></vue-timepicker>
          </div>
      </div>
    </div>
    <div
      v-if="display && newRecord"
      class="form-group repeat-options"
    >
      <label>Repeating Options</label>
      <select
        id="content_repeat_frequency"
        v-model="content.attributes['repeat-frequency']"
      >
        <option value="no_repeat">Does Not Repeat</option>
        <option value="day">Repeat Daily</option>
        <option value="week">Repeat Weekly</option>
        <option value="month">Repeat Monthly</option>
        <option value="year">Repeat Yearly</option>
      </select>
      <select
        v-if="term !== 'no_repeat'"
        id="content_repeat_interval"
        v-model="content.attributes['repeat-interval']"
      >
        <option value="1">every {{ term }}</option>
        <option
          v-for="i in 29"
          :key="i"
          :value="i + 1"
        >
          every {{ i + 1 }} {{ term }}s
        </option>
      </select>
      <div id="content_repeat_end_date" style="line-height: 2;">
        <datepicker placeholder="until date"  v-if="term !== 'no_repeat'" :format="customFormatterDate" class="form-control input-lg timepicker"  @selected="dateUntilChangeHandler" v-model="untilDateString"></datepicker>
      </div>
    </div>
    <div
      v-if="display && term == 'week'"
      class="button-group-justified form-group"
    >
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="0" />
        <span>Su</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="1" />
        <span>Mo</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="2" />
        <span>Tu</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="3" />
        <span>We</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="4" />
        <span>Th</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day" v-model="content.attributes['repeat-weekly-day']" type="radio" value="5" />
        <span>Fr</span>
      </label>
      <label>
        <input name="content_repeat_weekly_day"  v-model="content.attributes['repeat-weekly-day']" type="radio" value="6" />
        <span>Sa</span>
      </label>
    </div>
    <div
      v-if="display && term == 'month'"
      class="form-group radios inline-labels"
    >
      <label>
        <input
          v-model="monthlyRepeat"
          type="radio"
          value="by_week"
        />
        <div class="circle" />
        Day of the week
      </label>
      <label>
        <input
          v-model="monthlyRepeat"
          type="radio"
          value="by_month"
        />
        <div class="circle" />
        Day of the month
      </label>
    </div>
    <div
      v-if="display && term == 'month' && monthlyRepeat == 'by_week'"
      class="button-group-justified form-group"
    >
      <label>
        <input name="repeat_monthly_weeks" v-model="content.attributes['repeat-monthly-weeks']" type="radio" value="1" />
        <span>First</span>
      </label>
      <label>
        <input name="repeat_monthly_weeks" v-model="content.attributes['repeat-monthly-weeks']"  type="radio" value="2" />
        <span>Second</span>
      </label>
      <label>
        <input name="repeat_monthly_weeks" v-model="content.attributes['repeat-monthly-weeks']"  type="radio" value="3" />
        <span>Third</span>
      </label>
      <label>
        <input name="repeat_monthly_weeks" v-model="content.attributes['repeat-monthly-weeks']"  type="radio" value="4" />
        <span>Fourth</span>
      </label>
      <label>
        <input name="repeat_monthly_weeks" v-model="content.attributes['repeat-monthly-weeks']"  type="radio" value="last" />
        <span>Last</span>
      </label>
    </div>
    <div
      v-if="display && term == 'month' && monthlyRepeat == 'by_week'"
      class="button-group-justified form-group"
    >
      <label>
        <input name="repeat_monthly_days"  v-model="content.attributes['repeat-monthly-days']" type="radio" value="0" />
        <span>Su</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="1" />
        <span>Mo</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="2" />
        <span>Tu</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="3" />
        <span>We</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="4" />
        <span>Th</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="5" />
        <span>Fr</span>
      </label>
      <label>
        <input name="repeat_monthly_days" v-model="content.attributes['repeat-monthly-days']"  type="radio" value="6" />
        <span>Sa</span>
      </label>
    </div>
    <div
      v-if="display && term == 'month' && monthlyRepeat == 'by_month'"
      class="form-group monthly-options"
    >
      <select v-model="content.attributes['repeat-interval']">
        <option value="1">1st of every month</option>
        <option value="2">2nd of every month</option>
        <option value="3">3rd of every month</option>
        <option value="4">4th of every month</option>
        <option value="5">5th of every month</option>
        <option value="6">6th of every month</option>
        <option value="7">7th of every month</option>
        <option value="8">8th of every month</option>
        <option value="9">9th of every month</option>
        <option value="10">10th of every month</option>
        <option value="11">11th of every month</option>
        <option value="12">12th of every month</option>
        <option value="13">13th of every month</option>
        <option value="14">14th of every month</option>
        <option value="15">15th of every month</option>
        <option value="16">16th of every month</option>
        <option value="17">17th of every month</option>
        <option value="18">18th of every month</option>
        <option value="19">19th of every month</option>
        <option value="20">20th of every month</option>
        <option value="21">21st of every month</option>
        <option value="22">22nd of every month</option>
        <option value="23">23rd of every month</option>
        <option value="24">24th of every month</option>
        <option value="25">25th of every month</option>
        <option value="26">26th of every month</option>
        <option value="27">27th of every month</option>
        <option value="28">28th of every month</option>
        <option value="29">29th of every month</option>
        <option value="30">30th of every month</option>
        <option value="31">31st of every month</option>
      </select>
    </div>

    <div v-if="display && !newRecord && content.attributes['repeat-summary'] &&
    content.attributes['repeat-summary'] !== undefined" class="form-group radios" style="margin-top: 15px;">
      <label>
        <input name="update_all" type="radio" checked="checked" value="false" @click="setUpdateAll('false')">
        <div class="circle"></div>
        <div>
          Only Update This Date
        </div>
      </label>

      <label>
        <input name="update_all" type="radio" value="true"   @click="setUpdateAll('true')">
        <div class="circle"></div>
        <div>
          Update All Dates in the Series
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { userInfo } from "../common/user-info";
import { router, timezoneMapping, railsTzToMomentTz, datePickerConverter} from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
  export default {
    props: ["color", "optional", "type", "contentId", "populateOnCreate", "initialDate"],
    data() {
      return {
        //allDay: false,
        enabled: false,
        monthlyRepeat: 'by_week',
        toTimeString: "",
        fromTimeString: "",
        toDateString:  "",
        fromDateString: "",
        untilDateString: "",
        fromTime: {
          hh: '12',
          mm: '00',
          A: 'PM'
        },
        toTime: {
          hh: '01',
          mm: '00',
          A: 'PM'
        },
      };
    },
    computed: {
      ...mapState("contents", ["contentById", "allContentByContextTypeAndId"]),
      content(){
        return this.contentById[parseInt(this.contentId)]
      },
      display() {
        return this.enabled || !this.optional;
      },
      allDay () {
        var ad = this.content.attributes['all-day']
        return (ad && (ad == true))
      },
      newRecord() {
        return this.content.tempKey ? true : false
      },
      term() {
         return this.content.attributes['repeat-frequency'] ? this.content.attributes['repeat-frequency'] : 'no_repeat'
      },

      utcTimeZone() {
        //return TIMEZONE_MAPPING[this.usersById[userInfo().user_id].attributes['time-zone']]
        return "utc" //railsTzToMomentTz(userInfo().timezone)
      }
      
    },
    created(){
      if (this.populateOnCreate){
        this.populateFields()
      } else {
        let userTz = railsTzToMomentTz(userInfo().timezone)
        let tz = userTz ? userTz : this.content['converted-timezone']
        this.enabled = this.content.attributes['from-date'] ? true : false
        this.untilDateString = this.content.attributes['repeat-end-date'] ? this.content.attributes['repeat-end-date'] :  null
        this.content.attributes['to-date'] = this.content.attributes['to-date'] ? this.content.attributes['to-date'] : null
        this.content.attributes['from-date'] = this.content.attributes['from-date'] ? this.content.attributes['from-date'] : null
        this.fromTime = this.parseTime(this.content.attributes['from-time'] ? this.content.attributes['from-time']: null)
        this.toTime = this.parseTime(this.content.attributes['to-time'] ? this.content.attributes['to-time'] : null)
      }
      if (this.content.attributes['repeat-summary']) {
        this.setUpdateAll('false')
      }
    },
    methods: {
      calculateOneHourLater(time) {
        if (!time || !time.hh || !time.mm || !time.A) {
          return ''; // Return empty string or some default value if timeObj is not valid
        }

        // Reconstruct the time string from the time object
        const timeString = `${time.hh}:${time.mm} ${time.A}`;

        // Use moment.js to add one hour and format it back to a string
        return moment(timeString, "hh:mm A").add(1, 'hours').format("hh:mm A");
      },
     populateFields() {
       if (this.initialDate && this.initialDate !== ''){
         //launched from a click on a blank calendar entry
         this.fromDateString = this.initialDate 
         this.toDateString = this.initialDate 
         this.content.attributes['to-date'] = this.initialDate
         this.content.attributes['from-date'] = this.initialDate

       } else {
         this.fromDateString = this.content.attributes['from-date'] ? this.content.attributes['from-date'] : this.fromDateString ? this.fromDateString : datePickerConverter(new Date())
         if (this.allDay && this.content.attributes['to-date']){
           this.toDateString =  moment(this.content.attributes['to-date']).subtract(12, 'h').format("MM/DD/YYYY") 
           this.content.attributes['to-date'] =  moment(this.content.attributes['to-date']).subtract(12, 'h').format("MM/DD/YYYY") 
         } else {
           this.toDateString = this.content.attributes['to-date'] ? this.content.attributes['to-date'] :  datePickerConverter(new Date())
         }

       }
       this.untilDateString = this.content.attributes['repeat-end-date'] ? this.content.attributes['repeat-end-date'] :  datePickerConverter(new Date())
       this.fromTime = this.parseTime(this.content.attributes['from-time'] ? this.content.attributes['from-time']: "12:00 PM")
       this.toTime = this.parseTime(this.content.attributes['to-time'] ? this.content.attributes['to-time'] : "01:00 PM")
     },
     parseTime(t){
       if (t === null) {
         return ''
       }
       try {
        let to = {hh:"", mm:"", A:""}
        let sa = t.split(":")
        if (sa.length > 1){
          let hrInt = parseInt(sa[0])
          if (hrInt >= 12)
          {
            to.hh = hrInt > 12 ? (hrInt - 12) + "" :  "12"
            if (to.hh.length == 1){
              to.hh = "0" + to.hh
            }
            let sa2 = sa[1].split(" ")
            if (sa2.length > 1){
              to.mm = sa2[0]
            } else {
              to.mm = sa[1]
            }
            to.A = "PM"
          } else {
            to.hh = sa[0]
            if (to.hh == "0" || to.hh == "00"){
              to.hh = "12"
            }
            if (to.hh.length == 1){
              to.hh = "0" + to.hh
            }
            let sa2 = sa[1].split(" ")
            if (sa2.length > 1){
              to.mm = sa2[0]
              to.A = sa2[1]
            } else {
              to.mm = sa2[0]
              to.A = "AM"
            }
          }
        }
        return to
       } catch (e){
         console.log(e)
       }
     },
      setUpdateAll(value) {
        this.content.attributes['update-all'] = value;
      },
     toggleFields (){
      this.enabled = !this.enabled
      if (this.enabled){
        this.populateFields()
      } else {
        this.toDateString = ""//this.content.attributes['to-date'] ? this.content.attributes['to-date'] : datePickerConverter(new Date())
        this.fromDateString = "" //this.content.attributes['from-date'] ? this.content.attributes['from-date'] :  datePickerConverter(new Date())
        this.untilDateString = "" //this.content.attributes['repeat-end-date'] ? this.content.attributes['repeat-end-date'] :  datePickerConverter(new Date())
        this.content.attributes['to-date'] = ""//this.content.attributes['to-date'] ? this.content.attributes['to-date'] : datePickerConverter(new Date())
        this.content.attributes['from-date'] =  ""//this.content.attributes['from-date'] ? this.content.attributes['from-date'] :datePickerConverter(new Date())
        this.fromTime = "" //this.parseTime(this.content.attributes['from-time'] ? this.content.attributes['from-time']: "12:00 PM")
        this.toTime = ""//this.parseTime(this.content.attributes['to-time'] ? this.content.attributes['to-time'] : "01:00 PM")
      }
     },
      dateUntilChangeHandler (eventData) {
        this.untilDateString =  datePickerConverter(eventData) //monthmap[dtarr[1]] + "/" + dtarr[2] + "/" + dtarr[3]//eventData
        this.content.attributes['repeat-end-date'] =  this.untilDateString
      },


      dateFromChangeHandler (eventData) {
        this.fromDateString =  datePickerConverter(eventData) //monthmap[dtarr[1]] + "/" + dtarr[2] + "/" + dtarr[3]//eventData
        this.content.attributes['from-date'] =  this.fromDateString
        this.toDateString =  this.fromDateString
        this.content.attributes['to-date'] =  this.fromDateString
      },
      dateToChangeHandler (eventData) {
        this.toDateString =  datePickerConverter(eventData) //monthmap[dtarr[1]] + "/" + dtarr[2] + "/" + dtarr[3]//eventData
        this.content.attributes['to-date'] =  this.toDateString
      },
      timeChangeHandler (eventData, fieldName) {
        this.content.attributes[fieldName] = eventData.displayTime
      },
      customFormatterDate(date) {
        return datePickerConverter(date)
      }
    },
  };
</script>

