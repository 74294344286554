import { render, staticRenderFns } from "./DetailsIcon.vue?vue&type=template&id=6c71c204"
import script from "./DetailsIcon.vue?vue&type=script&lang=js"
export * from "./DetailsIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports