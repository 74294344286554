import axios from "axios";

import {
    buildServiceParamMap,
    RTAGS_URL
} from "@/common";
import { userInfo } from "@/common/user-info"
import jwtInterceptor  from '../../common/jwt-interceptor';

const actions = {
    async fetchCategoriesByRally({commit, state}, inparams) {
        const {rallyId} = inparams;
        const user = userInfo();
        var jsonparams = {
            context_id: rallyId,
            context_type: 'Rally',
            type: 'CommunityCategory',
            "page[number]": "1",
            "page[size]": "500",
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RTAGS_URL() + '/tag-types',
            params
        };
        const response = await jwtInterceptor(requestOptions);

        return response.data.data;
    },
    async fetchRallyRelationshipsByTagTypeId({commit, state}, inparams) {
        const {tagTypeId} = inparams;
        const user = userInfo();
        var jsonparams = {
            tag_type_id: tagTypeId,
            taggable_type: 'RallyRelationship',
            "page[number]": "1",
            "page[size]": "500",
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RTAGS_URL() + '/tags',
            params
        };
        const response = await jwtInterceptor(requestOptions);

        return response.data.data;
    },

}

export default {
    namespaced: true, // Make this module namespaced
    actions,
};