<template>
  <article>
    <div  v-on:click="$emit('perform', 'showLightbox', content)" class="avatar" :style="style"></div>
    <!-- Floating download icon -->
    <br/>
      <a  class="download-icon" @click.prevent="downloadImage" :href="'#'" >
      <i class="fal fa-download" ></i>
      </a>
   
  </article>
</template>

<script>
import { imgUrl, downloadFile } from "../../../common";

export default {
  name: "Photo",
  props: {
    content: Object,
    channel: Object,
    rally: Object
  },
  computed: {
    style() {
      return `background-image: url('${imgUrl(this.content)}');`;
    },
  },
  methods: {
    downloadImage(){
      console.log(this.content)
      let href = this.content.attributes['original-url']
      let file_name = this.content.attributes['attachment-file-name']
      downloadFile(href, file_name )
    }
  }
};
</script>


