<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";

export default {
  name: "Member",
  props: ["memberProp"],
  computed: {
    ...mapState("user", ["usersById"]),
    ...mapState("contents", ["contentById"]),
    ...mapState("memberships", [
      "membersByRallyId",
      "membersByMemberId",
      "profileByMembershipId"
    ]),
    memberUser: function() {
      return this.member ? this.usersById[parseInt(this.member.attributes["user-id"])] : null
    },
    avatar: function() {
      let memUserId = this.member && this.member.attributes && this.member.attributes["user-id"] ? this.member.attributes["user-id"] : null
      if (memUserId){
        let myuser = this.usersById[parseInt(this.member.attributes["user-id"])];
        if (myuser){
          return this.contentById[myuser.relationships.avatar.data.id];
        } else {
          return null
        }
      } else {
        return null
      }
    },
    rallyId() {
      return this.$route.params.rallyId;
    },
    member: function() {
      if (this.memberProp) {
        return this.memberProp;
      } else {
        return this.membersByMemberId[parseInt(this.$route.params.id)];
      }
    },
    profile: function() {
      try {
      return this.profileByMembershipId[this.member.id];
      } catch (e){
        console.log(e)
      }
      return null
    }
  },
  methods: {
    ...mapActions("memberships", ["deleteMembership", "updateMemberRole", "inviteMember", "resendInvitation","acceptInvite", "rejectInvite"]),
    log(message) {
      this.$log.debug(message);
    },
    memberField(names) {
      var field = "";
      if (this.member.attributes["is-custom"] == true || this.member.attributes["is-custom"] == 'true' ){
        names.forEach(n => {
          if (this.profile && this.profile.attributes[n]) {
            field = this.profile.attributes[n];
          }
        });
      } else {
          names.forEach(n => {
            if (this.memberUser.attributes[n]) {
              field = this.memberUser.attributes[n];
            }
          });
      }
      return field
    },
    memberFieldOLD(names) {
      var field = "";
        names.forEach(n => {
          if (this.profile && this.profile.attributes[n]) {
            field = this.profile.attributes[n];
          }
        });
        if (!field){
          names.forEach(n => {
            if (this.memberUser[n]) {
              field = this.memberUser[n];
            }
          });
        }
      return field
    },
    editProfile() {
      router.push(`/${this.rallyId}/edit?tab=myrallyprofile`);
    },
    reinvite() {
      if ( confirm("Would you like to re-send this person's invitation?") ) {
        this.resendInvitation({member: this.member})
        this.$store.dispatch('user/flashSuccess', 'This person has been reinvited.');
      }
    },
    async remove() {
      if ( confirm("Are you sure that you would like to remove this person?") ) {
        //this.inviteMember({rallyId: this.rallyId,  member:this.member, inviteAction: "kick"})
        try {
          await this.deleteMembership({rallyId: this.rallyId, memberId: this.member.id})
        } catch (e){
          console.log(e)
        }
        router.push(`/${this.rallyId}/directory`); 
      }
    },
    approve() {
      //this.inviteMember({rallyId: this.rallyId,  member:this.member, inviteAction: "approve"})
      this.acceptInvite({member:this.member, rallyId: this.rallyId })
      this.$store.dispatch('user/flashSuccess', 'This request to join has been approved.');
    },
    deny() {
      if ( confirm("Would you like to deny this request to join?") ) {
        //this.inviteMember({rallyId: this.rallyId,  member:this.member, inviteAction: "denied"})
        this.rejectInvite({member:this.member, rallyId: this.rallyId })
        this.$store.dispatch('user/flashSuccess', 'This request to join has been denied.');
      }
    }
  }
};
</script>
