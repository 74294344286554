<template>
 <div>
  <div :class="[{ open: noSearchResultsFlag }, 'modal']">
      <div class="shadow" @click="closeModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeModal"></i>
          <div class="heading">No Results</div>
          <div class="info">
            No search results found!
          </div>
          <div class="actions">
            <button class="secondary" @click="closeModal">Close</button>
          </div>
    </div>
  </div>
  <UploadLimitModal ref="uploadLimitModalRef"/>
  
  <div v-if="checkReloadData">
    <main :class="mainClass">
      <input
        hidden
        ref="filePicker"
        type="file"
        multiple
        @change="onFileUpload"
       accept="image/*, .ppt, .pptx, .pdf, .doc, .docx, .xml, .xls, .xlsx, .csv, .txt, .rtf, .mov, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
      <input
        hidden
        ref="imagePicker"
        type="file"
        multiple
        @change="onImageUpload"
        accept="image/*"
      />
      <ToolBar
        :back="back"
        :channelId="channelId"
        v-if="toolbarOptions"
        :options="toolbarOptions"
        :allow-status-filter="allowStatusFilter"
        :allow-my-contents-filter="allowMyContentsFilter"
        :my-contents-label="myContentsLabel"
        v-on:perform="perform"
      />
      <PhotoLightbox
        :channel="channel"
        :rally="rally"
        :photo="currentPhoto"
        :photos="imageList"
        v-on:perform="perform"
      />
      <div style="padding-left:15px"><div
        id="progress-bar"
        class="progress-bar"
        :style="progressBarStyle"
      >Uploading: {{this.uploadCount}}</div></div>
      <StandardContent
        v-if="currentContent"
        v-on:perform="perform"
        :channel="channel"
        :content="currentContent"
        :detailed="true"
        :id="currentContent.id"
        :contentType="currentContent.contentType"
        :observer="observer"
        :rally="rally"
        :parentContextId =  "contextId"
      />
      <section class="content-area" v-if="contentSearchResults && contentSearchResults.length > 0" >
        <StandardContent
          v-for="content in contentSearchResults"
          v-on:perform="perform"
          :channel="channel"
          :rally="rally"
          :content="content"
          :key="content.id"
          :parentContextId =  "contextId"
        />
      </section>
      <section v-if="this.noSearchResultsFlag">
        <br />
        <br />
        <br />
        <br />
        <h3 class="title" v-if="alertNoResults" style="text-align:center">No results!</h3>
      </section>
      <EmptyState v-if="isEmpty && !isChildContent" :channelType="channel.attributes.type" />
      <section class="content-area" v-if="!this.noSearchResultsFlag && (!contentSearchResults || contentSearchResults.length == 0)" >
        <StandardContent
          v-for="content in sortedContents(filteredContents)"
          v-on:perform="perform"
          :channel="channel"
          :content="content"
          :contentId="content.id"
          :contentType="content.attributes['content-type']"
          :key="content.id"
          :parentContextIds = "parentContextIds" 
          :parentContextId =  "contextId"
          :observer = observer
          :rally="rally"
        />
        <infinite-loading ref="infLoad" @infinite="infiniteHandler">
          <span slot="no-more"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
          <span slot="no-results"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
        </infinite-loading>
      </section>
    </main>
  </div>

  </div>
</template>

<script>
import { Plugins } from "@capacitor/core";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  router,
  slugToTypeObjectMap,
  findDefaultChannelOfTypeForRally,
  findDefaultChannelObjOfType,
  kebabCase, channelTypeObjectMap, compareBy
} from "../common";
import { userInfo } from "../common/user-info";
import ContentMixin from "../components/ContentMixin";
import ContentReloadMixin from "../components/ContentReloadMixin";
import UploadLimitModal from "../components/UploadLimitModal.vue";
import { eventBus } from "../main.js";
export default {
    mixins: [
    ContentMixin,
    ContentReloadMixin 
  ],
  components: {
    UploadLimitModal
  },
  props: ["channel", "rally"],
  data() {
    return {
      fileLimitUploads: false,
      currentPhoto: null,
      loadAttempted: false,
      prevLoadCount: 0,
      prevLoadCountSameCount: 0,
     // channelId: 0,
      contextId: "0",
      contextType: "Channel",
      observer: null,
      pageMap: [],
      parentContainers: [],
      parentContextIds: [],
      parentContextTypes: [],
      rhWebParams: null,
      uploadProgress: 30,
      statusFilter: 1,
      channelActions: {
        MessageWall: [
          {
            onClick: "newInline",
            params: { contentType: "WallMessage" },
            icon: "fa-plus-circle",
            label: "New Message",
            permission:"writable"
          },
          {
            onClick: "openSearch",
            params: { context:  "Message"},
            icon: "fa-search",
            label: "Search",
            permission:"readable"
          },
          {
            onClick: "openSettings",
            params: { context:  "Message"},
            icon: "fa-cog",
            label: "Settings",
            permission:"editable"
          }
        ],
        Files: [
          /*{ onChange: "uploadPhoto", params: { contentType: "Photo" }, icon: "fa-plus-circle", label: "Add File" }*/
          {
            onClick: "showFilez",
            params: { contentType: "File" },
            icon: "fa-plus-circle",
            label: "New File",
            permission:"writable"
          },
          {
            onClick: "newInline",
            params: { contentType: "Folder" },
            icon: "fa-folder",
            label: "New Folder",
            permission:"writable"
          },
          {
            onClick: "openSearch",
            params: { context:  "File"},
            icon: "fa-search",
            label: "Search",
            permission:"readable"
          },
          {
            onClick: "openSettings",
            params: { context:  "File"},
            icon: "fa-cog",
            label: "Settings",
            permission:"editable"
          }
        ],
        GivePayableGroup: [
          {
            onClick: "openRHWeb",
            params: { returnTo: 'fundraisers/new?fundraiser_type=GivePayableGroup',
              heading: 'Awesome, Looks Like You Are Ready to Create a Give page!',
              description: 'Please click the blue button to head to the mobile web app to get started.'},
              icon: "fa-plus-circle",
              label: "New Fundraiser",
              permission:"writable"
          }
        ],
        Links: [
          {
            onClick: "newInline",
            params: { contentType: "Link" },
            icon: "fa-plus-circle",
            label: "Add Link",
            permission:"writable"
          },
          {
            onClick: "newInline",
            params: { contentType: "Board" },
            icon: "fa-border-all",
            label: "New Board",
            permission:"writable"
          },
          {
            onClick: "openSettings",
            params: { context:  "Link"},
            icon: "fa-cog",
            label: "Settings",
            permission:"editable"
          }
        ],
        Gallery: [
          {
            onClick: "showImagez",
            params: { contentType: "Photo" },
            icon: "fa-plus-circle", 
            label: "Add Photo",
            permission:"writable"
          },
          {
            onClick: "newInline",
            params: { contentType: "Album" },
            icon: "fa-book",
            label: "New Album",
            permission:"writable"
          },
           {
            onClick: "openSearch",
            params: { context:  "Photo"},
            icon: "fa-search",
            label: "Search",
            permission:"readable"
          },
          {
            onClick: "openSettings",
            params: { context:  "Photo"},
            icon: "fa-cog",
            label: "Settings",
            permission:"editable"
          }
        ],
        PayableGroup: [
          {
            onClick: "openRHWeb",
            params: { returnTo: 'fundraisers/new?fundraiser_type=PayableGroup',
              heading: 'Awesome, Looks Like You Are Ready to Create a Payment page!',
              description: 'Please click the blue button to head to the mobile web app to get started.'},
              icon: "fa-plus-circle",
              label: "New Payment",
              permission:"writable"
          }
          /*{ click: "foo", icon: "fa-plus-circle", label: "New Cause" },
          { click: "foo", icon: "fa-toggle-on", label: "Active" },
          { click: "foo", icon: "fa-toggle-off", label: "Draft" },
          { click: "foo", icon: "fa-archive", label: "Archived" },
          { click: "foo", icon: "fa-heart", label: "My Donations" }*/
        ],
        Signups: [
          {
            onClick: "newInline",
            params: { contentType: "SignupEvent" },
            icon: "fa-plus-circle",
            label: "New Sign-Up",
            hideAtTopLevel: true,
            permission:"writable"
          },
          {
            onClick: "newInline",
            params: { contentType: "SignupList" },
            icon: "fa-list-ul",
            label: "New List",
            permission:"writable"
          },
          {
            onClick: "openSettings",
            params: { context:  "SignupList"},
            icon: "fa-cog",
            label: "Settings",
            permission:"editable"
          },
          {
            onClick: "download",
            params: { context:  "SignupEvent"},
            icon: "fa-download",
            label: "Download",
            hideAtTopLevel: true,
            permission:"writable"
          }

          /*{ click: "foo", icon: "fa-toggle-on", label: "Active" },
          { click: "foo", icon: "fa-toggle-off", label: "Draft" },
          { click: "foo", icon: "fa-archive", label: "Archived" },
          { click: "foo", icon: "fa-download", label: "Download" }*/
        ],
        Fundraiser: [
          {
            onClick: "openRHWeb",
            params: { returnTo: 'fundraisers/new?fundraiser_type=PayableEvent',
              heading: 'Awesome, Looks Like You Are Ready to Create a Special Event!',
              description: 'Please click the blue button to head to the mobile web app to get started.'},
            icon: "fa-plus-circle",
            label: "New Event",
            permission:"writable"
          }
        ],
        Payables: [
          {
            onClick: "openRHWeb",
            params: { returnTo: 'fundraisers/new?fundraiser_type=PayableEvent',
              heading: 'Awesome, Looks Like You Are Ready to Create a Special Event!',
              description: 'Please click the blue button to head to the mobile web app to get started.'},
              icon: "fa-plus-circle",
              label: "New Event",
              permission:"writable"
          }
          /* { click: "foo", icon: "fa-plus-circle", label: "New Event" },
          { click: "foo", icon: "fa-toggle-on", label: "Active" },
          { click: "foo", icon: "fa-toggle-off", label: "Draft" },
          { click: "foo", icon: "fa-archive", label: "Archived" },
          { click: "foo", icon: "fa-heart", label: "My Events" }*/
        ]
      }
    };
  },
  computed: {
    ...mapState("contents", ["uploadCount", "allContentByContextTypeAndId", "resultCountByTypeAndId", "contentSearchResults", "noSearchResultsFlag", "maxPageByTypeAndId"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    allowMyContentsFilter() {
      if (["Payables", "PayableGroup", "GivePayableGroup"].includes(this.channelType)) {
        return true;
      } else {
        return false;
      }
    },
    allowStatusFilter() {
      if (["Payables", "PayableGroup", "GivePayableGroup", "Signups"].includes(this.channelType)) {
          return true;
        } else {
        return false;
      }
    },
    channelId() {
      return this.channel.id
    },
    myContentsLabel() {
      if (this.channelType == "Payables") {
        return "My Events"
      } else if (this.channelType == "PayableGroup") {
        return "My Payments"
      } else if (this.channelType == "GivePayableGroup") {
        return "My Donations"
      } else {
        return ""
      }
    },
    openRhWebUniqueId(){
      return  this.rallyId + this.channelSlug 
    },
    latestRhWebParams() {
      return this.rhWebParams
    },
    checkReloadData() {
      if ((!this.contents || this.contents.length == 0) && !this.loadAttempted ) {
        this.loadPageData();
        return false
      } 
      return true;
    },

    totalCount(){
      return this.resultCountByTypeAndId[this.contextId] ? this.resultCountByTypeAndId[this.contextId] : 0
    },

    isLoadingComplete(){
      return ((this.contents &&  this.contents.length >= this.totalCount-1 ) || this.prevLoadCountSameCount >= 2)
    },

    imageList() {
      if (this.contents && this.contents.length > 0) {
        return this.contents
          .map(a => {
            if (a.attributes["original-url"]) {
              return a;
            } else {
              return null;
            }
          })
          .filter(a => {
            if (a) {
              return a;
            }
          });
      } else {
        return [];
      }
    },
    rallyId() {
      return parseInt(this.$route.params.rallyId);
    },
    back() {
      // Determine whether or not to display the "back" arrow in the toolbar.
      return this.parentContextIds && this.parentContextIds.length > 0 ? "navUp" : "";
    },

    channelSlug() {
      // Used in place of channel.type.
      return this.channel.attributes.slug
    },
    channelType() {
      return this.channel.attributes.type
    },
    channelTypePlural() {
      //TODO modify this to use the channel.type to get the channelTypePlural
      return channelTypeObjectMap()[this.channelType].channelTypePlural;
    },
    contentType() {
      return channelTypeObjectMap()[this.channelType].contentType;
    },
    subContentType() {
      return channelTypeObjectMap()[this.channelType].type;
    },
    currentContent() {
      return this.parentContainers ? this.parentContainers[this.parentContainers.length - 1]: null
    },
    mainClass() {
      return kebabCase(this.channelSlug) + " index";
    },
    myType() {
      return channelTypeObjectMap()[this.channelType].type;
    },
    newAction() {
      return channelTypeObjectMap()[this.channelType].newAction;
    },
    progressBarStyle() {
      if ( this.uploadCount == 0 ) {
        return 'display: none;';
      } else {
        return "width: " + this.uploadProgress + "%;upload-count:" + this.uploadCount;
      }
    },

    contents() {
      return this.allContentByContextTypeAndId[parseInt(this.contextId ? this.contextId : this.channelId)];
    },
    filteredContents(){
      const cs = this.contents//this.allContentByContextTypeAndId[parseInt(this.contextId ? this.contextId : this.channelId)];
      if (cs && cs.length > 0){
        return cs.filter(function(s) {
          return !(s.attributes && s.attributes.title && s.attributes.title.indexOf('[untitled]') >= 0 ) &&
              (s.attributes['status'] == 1 ||
                  s.attributes['status'] == 2 ||
                  s.attributes['status'] == 0 ||
                  s.editMode == true || s.attributes['lifecycle'] == "new")
        })
      } else {
         return []
      }
    },
    isEmpty() {
      return this.filteredContents && this.filteredContents.length == 0
    },
    isChildContent() {
      return (this.parentContainers && this.parentContainers.length > 0)
    },
    toolbarOptions() {
      let options = new Array();
      //check if page data is loaded or not
      if (
        this.rallyChannelsByRallyId &&
        this.rallyChannelsByRallyId[this.rallyId]
      ) {
        // Consult membership.role and channel.members_write
        var chanObj = findDefaultChannelObjOfType(
          this.channelTypePlural,
          this.rallyChannelsByRallyId[this.rallyId]
        );
        //the actions should all be hidden if the user doesn't have edit right now
        //if (chanObj.attributes.editable) {
          for (let button of this.channelActions[this.channelType]) {
            if (chanObj.attributes[button.permission] === true){
              options.push(button);
            }
          }
        //}

        // Present for everyone
        if (["Files", "Links", "Gallery"].includes(this.channelType)) {
          //options.push({ onClick: "refreshContent", params: { }, icon: "fa-sync", label: "Refresh" });
          //TODO fix this
          // options.push({ onClick: "openSearch", params: { context: 'content' }, icon: "fa-search", label: "Search" });
        }

        // This should only be true for rally admin. TODO: Fix
        // if (chanObj.attributes.editable) {
        // options.push({ onClick: "openSettings", params: { }, icon: "fa-cog", label: "Settings" });
        // }
      }
      return options;
    },

    //pay url from old web: http://rcs.staging.rallyhood.com:80/api/v1/contents.json?content_type=Container&context_rally_id=51633&from_date=11%2F18%2F2022&include=shares&page%5Bnumber%5D=1&page%5Bsize%5D=9&query=&status=1&timezone=Mountain+Time+%28US+%26+Canada%29&type=PayableGroup
    contentParams() {
      var cp = {
        contextRallyId: this.rally.id,
        contextType: this.contextType,//"Channel",
        contextChannelId: this.channelId,
        contextId: this.contextId ? this.contextId : this.channelId,
        contentType: this.contentType,
        type: this.subContentType,
        include: ["shares", "attachments"],
        status: parseInt(this.statusFilter),
        customPageSize: ["Files", "Links", "Gallery", "Payables", "Signups"].includes(this.channelType) ?  100 : 5
        //visibility:["wall_only", "all"]
        //pageNumber: pnum,
        //type: this.myType //NOTE: causes MessageWall to no load

      };
      return cp;
    }
  },
  created() {
    // I don't think this is needed any more as the rally should be loaded by RallyPage before we ever get here now.
    //await this.fetchRalliesWithInfo({rallyId: this.rallyId});
    let myRally = this.allRalliesByRallyId[this.rallyId]
    // TODO figure out logic to redirect to rallies page if not standard
    /*if  ((myRally?.rally?.attributes['rally-type'] !== 'Standard' && myRally?.rally?.attributes['rally-type'] !== null &&
        myRally?.rally?.attributes['rally-type'] !== undefined) ||
        (myRally?.attributes['rally-type'] !== 'Standard' && myRally?.attributes['rally-type'] !== null &&
            myRally?.attributes['rally-type'] !== undefined))

    {
      router.push({ path: '/rallies' })
    }*/
    if (this.channelId){
      this.prevLoadCount = 0
      this.prevLoadCountSameCount= 0
      this.clearContentByContextId({contextId: this.channelId})
      this.loadPageData()
    }
    this.contextId = this.channelId
    this.observer = new IntersectionObserver(
        this.onElementObserved,
        {
          root: this.$el,
          threshold: 1.0,
        }
    );
    this.clearContentSearchResults({})
    window.scrollTo(0, 0);
    eventBus.$on('navUp', () => {
      this.navUp();
    })
    eventBus.$on('lightboxHide', () => {
      this.handleLightboxHide();
    })
  },
  watch: {
    $route(to, from) {
      if (this.channelId){
        this.prevLoadCount = 0
        this.prevLoadCountSameCount= 0
        this.clearContentByContextId({contextId: this.channelId})
      }
      this.loadPageData();
    },
    /*noSearchResultsFlag: function(oldv, newv){
      console.log("nosearchresults flag changed")
      alert("flag changed")
    },*/
    rallyChannelsByRallyId: function(newById, oldById) {
      if (!this.channelId) {
        this.loadContents();
      }
    },
    allContentByContextTypeAndId: function(newById, oldById) {
      if (this.uploadProgress > 0) {
        this.uploadProgress = 95;
      }
    },
  },

  methods: {
    ...mapActions("contents", [
      "fetchContents",
      "uploadFile",
      "createContents",
      "uploadFileMultipart",
      "updateContent",
      "clearContentSearchResults",
      "setNoSearchResultsFlag",
      "clearContentByContextId"
    ]),
    ...mapActions("rallies", ["fetchRalliesWithInfo"]),
    ...mapActions("user", ["fetchUsersById"]),
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("activities", ["contentViewed"]),
    reloadPage(){
      location.reload()
    },
    sortedContents(cs){
        if (cs && cs.length > 0 && ((cs[0]['attributes']['content-type'] ==  'Fundraiser') || (cs[0]['attributes']['content-type'] ==  'Event'))) {
          return cs.sort(compareBy({}, "converted-start"))
        } else {
          return cs
        }
    },
    closeModal(){
      this.setNoSearchResultsFlag({flag:false})
    },
    closeFileLimitModal(){
      this.fileLimitUploads = false;
    },
    handleContentFilterChange(status) {
      //this.statusFilter = status;
      let filterParams = { ...this.contentParams };
      filterParams['ignorePagination'] = true
      filterParams['status'] = status
      if(parseInt(status) == 0){
        filterParams['creatorId'] = userInfo().id
      }
      if(parseInt(status) == 2 && !this.rally.attributes.editable) {
        filterParams['creatorId'] = userInfo().id
      }
      if(parseInt(status) == 3) {
        filterParams['status'] = 1
        filterParams['childContentParticipantId'] = userInfo().id
      }

      this.clearContentByContextId({contextId: this.channelId})
      this.fetchContents(filterParams);
    },
    myReloadPageData() {
        this.uploadProgress = 30;
        this.pageMap[this.myType] = 1;
    },
    myFetchContents(){
      this.fetchContents(this.contentParams);
    },
    cancelNewInline() {
      this.contents.shift();
    },
    showLightbox(image) {
      this.currentPhoto = image;
    },
    handleLightboxHide() {
      this.currentPhoto = null;
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
        if (!isIntersecting) {
          return;
        }

        let contentId = target?.attributes?.contentid?.value;
        let contentType = target?.attributes?.contenttype?.value;

        if (contentId && contentType){
          let params = {}
          params['contentId'] = contentId
          params['contentType'] = contentType
          params['actionType'] = 'viewed'
          params['rallyId'] = this.rallyId
          params['userId'] = userInfo()['id']
          this.contentViewed(params);
        }
      })},
    onFileUpload(e) {
        if (e.target.files.length > 10){
           this.$refs.uploadLimitModalRef.showModal()
        } else {
          for (var i =0;i < e.target.files.length; i++){
            this.uploadFilez(e.target.files[i]);
          }
        }
    },
    onImageUpload(e) {
      if (e.target.files.length > 10){
          this.$refs.uploadLimitModalRef.showModal()
        } else {
          for (var i =0;i < e.target.files.length; i++){
            this.uploadFilez(e.target.files[i], "Photo");
          }
      }
    },
    showFilez() {
      this.$refs.filePicker.click();
    },
    showImagez() {
      this.$refs.imagePicker.click();
    },
    async loadNextPage(){
      // --- Check to see if we are loading contents within a container
      // --- If so, we need to fetch the contents of the container
      if(this.parentContainers.length > 0) {
        await this.fetchContents({
          contextType: "Container",
          contextChannelId: this.channelId,
          contextId: this.parentContainers[this.parentContainers.length - 1].id,
          contentType: ["Binary", "Link", "Container", "Event", "Attachment"],
          customPageSize: ["Files", "Links", "Gallery", "Payables", "Signups"].includes(this.channelType) ?  100 : 5
        });
      } else {
        // --- Otherwise just fetch the next page of the channel
        console.log("fetching contents else")
        await this.fetchContents({
          ...this.contentParams
        })
      }
    },

     async infiniteHandler($state) {
      let beforesize = this.contents.length
      console.log("sci in:")
      await this.loadNextPage();
      if (beforesize == this.contents.length){
        console.log("complete!!!!" + beforesize + "=>" + this.contents.length)
        $state.complete(); 
      } else {
        console.log("loaded!!!!" + beforesize + "=>" + this.contents.length)
        $state.loaded();
      }
      
    },
    followLink(theUrl) {
      window.open(theUrl, "_blank");
    },

    navUp() {
      this.parentContainers.pop();
      this.contextId = this.parentContextIds.pop();
      this.contextType = this.parentContextTypes.pop();
      this.prevLoadCount = 0
      this.prevLoadCountSameCount= 0
      //this.clearContentByContextId({contextId: this.channelId})
    },
    newInline(params) {
      if (!this.contents){
        this.contents = []
      }
      if (params.contentType == "SignupEvent"){
            let params = {}
            params['type'] = 'SignupEvent'
            params['contentType'] = 'Event'
            params['contextType'] = 'Channel'
            params['contextChannelId'] = this.channelId
            params['contextId'] = this.channelId
            params['rallyId'] = this.rallyId
            params['contextRallyId']= this.rallyId
            params['maxParticipants'] = '1'
            params['privacy'] = "1"
            params['title'] = ''
            params['status'] = "0"
            params['editMode'] = true
            if (this.parentContainers && this.parentContainers.length > 0) {
              //if it's a subfolda then do this...
              params["contextId"] = this.parentContainers[0].id
              params["contextType"] = "Container"
            }
            this.createContents(params)
            //params['tempKey']=  this.tempKey
      } else {
        this.contents.unshift({
          editMode: true,
          attributes: {
            type: params.contentType,
            lifecycle: "new",
            status: 0,
            editable: true,
            deleteable: true
          }
        });
      }
    },
    openContainer(container) {
      const containerId = container.id;
      this.clearContentSearchResults({})
      this.parentContainers.push(container);
      this.parentContextIds.push(this.contextId);
      this.parentContextTypes.push(this.contextType);
      this.contextId = containerId;
      this.contextType = "Container"
      this.clearContentByContextId({contextId: containerId})
      let fetchParams = {
        contextType: "Container",
        contextChannelId: this.channelId,
        contextId: containerId,
        contentType: ["Binary", "Link", "Container", "Event", "Attachment"],
        pageNumber: 1,
        customPageSize: ["Files", "Links", "Gallery", "Payables", "Signups"].includes(this.channelType) ?  100 : 5
      }
      if(container.attributes.type == "SignupList"){
        fetchParams['excludeParticipations'] = true
      }
      this.fetchContents(fetchParams);
    },
    openContent(content) {
      const contentId = content.id;
      this.openContainer(content);
      let path = `/${this.rallyId}/${this.channelSlug}/${contentId}`; 
      if (this.channelSlug.indexOf("?") > -1){
        let channelSlugBase = this.channelSlug.split('?')[0]
        let channelSlugParams = this.channelSlug.split('?')[1]
        path = `/${this.rallyId}/${channelSlugBase}/${contentId}?${channelSlugParams}`; 
      }
      router.push(path);
      if (this.loadPageData) {
        this.loadPageData();
      }
    },
    newContent() {
      const path = `/${this.rallyId}/${this.newAction}`;
      router.push(path);
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    clearParentContainers() {
      this.parentContainers = []
      this.parentContextIds = []
      //this.contextId = 
      this.contextType = "Channel"
      this.prevLoadCount = 0
      this.prevLoadCountSameCount= 0
      this.clearContentByContextId({contextId: this.channelId})
    },
    saveNewInline(p) {
      this.contents.shift();

      var params = {
        contentType: p.contentType,
        contextId: this.channelId,
        contextType: this.contextType,
        type: p.type,
        contextChannelId: this.channelId,
        contextRallyId: this.rallyId,
        status: (p.status === undefined) ? 1 : p.status
      }
      if (p.rallyId){
        params['rallyId'] = p.rallyId
      }
      if (p.title){
        params['title'] = p.title
      }
      if (p.description){
        params['description'] = p.description
      }
      if (p.maxParticipants){
          params['maxParticipants']= p.maxParticipants
      }
      if (p.url){
        params['url'] = p.url
      }

      if (p['update-all']) {
        params['update-all'] = p.update_all
      }
      //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"content_type"=>"Container", "context_id"=>"290812", "context_type"=>"Channel", "title"=>"FOLDA", "type"=>"Album", "context_channel_id"=>"290812", "context_rally_id"=>"38720", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar"}}}
      if (this.parentContextIds && this.parentContextIds.length > 0) {
        //if it's a subfolda then do this...
        params["contextId"] = this.contextId;
        params["contextType"] = "Container";
      }
      this.createContents(params);
    },
    displayUploadProgress() {
      setTimeout(
        function() {
          //console.log("uploadprogress:" + this.uploadProgress + " " + this.uploadCount)
          if (this.uploadCount > 0) {
            if (this.uploadProgress > 90){
              this.uploadProgress = 30
            }
            this.uploadProgress += 1;
            this.displayUploadProgress();
          } 
        }.bind(this),
        100
      );
    },

    //create at channel level
    //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "content_type"=>"Binary", "context_id"=>"306615",
    //"context_type"=>"Channel", "type"=>"Photo", "context_channel_id"=>"306615", "context_rally_id"=>"41405", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar", "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}

    //create at folda level
    //Started POST "/api/v1/contents.json" for 127.0.0.1 at 2021-05-25 16:24:12 -0500
    //Processing by Api::V1::ContentsController#create as JSON
    // Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "content_type"=>"Binary", "context_id"=>"19024593", "context_type"=>"Container", "type"=>"Photo", "context_channel_id"=>"290812", "context_rally_id"=>"38720", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar", "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
    uploadPhoto(img) {
      var params = {
        contentType: "Binary",
        contextId: this.channelId,
        contextType: "Channel",
        type: "Photo",
        contextChannelId: this.channelId,
        contextRallyId: this.rallyId,
        rallyId: this.rallyId,
        attachment: img.dataUrl.split(",")[1],
        attachmentContentType: img.info.type,
        attachmentFileName: img.info.name,
        fileData: img,
      };
      if (this.parentContextIds && this.parentContextIds.length > 0) {
        //if it's a subfolda then do this...
        params["contextId"] = this.contextId;
        params["contextType"] = "Container";
      }
      this.uploadFile(params);
      this.displayUploadProgress();
    },

    uploadFilez(f, type= "File") {
      var reader = new FileReader();
      reader.onload = e => {
        var params = {
          contentType: "Binary",
          contextId: this.channelId,
          contextType: "Channel",
          type,
          contextChannelId: this.channelId,
          rallyId: this.rallyId,
          contextRallyId: this.rallyId,
          attachment: reader.result.split(",")[1],
          attachmentContentType: f.type,
          attachmentFileName: f.name, 
          fileData: f,
        };
        if (this.parentContextIds && this.parentContextIds.length > 0) {
          //if it's a subfolda then do this...
          params["contextId"] = this.contextId;
          params["contextType"] = "Container";
        }
        this.displayUploadProgress();
        this.uploadFile(params);
      };
      try {
        reader.readAsDataURL(f);
      } catch (e) {
        console.log(e);
      }

      // this.displayUploadProgress();
    },
    moveFolderInParent(destinationFolderId) {
      // Implement the logic in the parent component's method
      console.log('Move folder in parent:', destinationFolderId);
      // Call any other methods or perform necessary operations in the parent component
    }
  }
};
</script>
