import {buildServiceParamMap, enrichDataWithIncluded, RMEMS_URL,} from "@/common";
import {userInfo} from "@/common/user-info"
import jwtInterceptor from '../../common/jwt-interceptor';

const state = {

};

const mutations = {

};

const getters = {
};

const actions = {
    async fetchMemberships({commit, state }, inParams){
        const user = userInfo();

        var {rallyIds, userIds, status = ["Active", "Invited"], role = ["Owner", "Subscriber", "Admin", "Friend"], include, include_admin_archived = true, pageSize, pageNumber} = inParams;
        var jsonparams = {
            role: role,
            include: include,
            include_admin_archived: include_admin_archived,
            sort: 'archived,sort_order,title',
            status: status,
            "page[number]": pageNumber,
            "page[size]": pageSize,
        };

        if (rallyIds) {
            jsonparams.rally_id = rallyIds;
        }
        if (userIds) {
            jsonparams.user_id = userIds;
        }

        var params = buildServiceParamMap(jsonparams);
        //params.append("sort", "archived%2C+sort_order%2C+title");???
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RMEMS_URL(),
            params: params
        };
        try {
            const response = await jwtInterceptor(requestOptions);

            if (include) {
                return enrichDataWithIncluded(response);
            } else {
                return response.data.data;
            }

        } catch (error) {
            console.error('Error fetching rallies:', error);
            throw error; // Rethrow for further handling
        }
    },
};

export default {
    namespaced: true, // Make this module namespaced
    state,
    mutations,
    getters,
    actions,
};