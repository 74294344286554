<template>
  <div>
    <div>
      <RallyNav v-if="isRallyLoaded" :rally="this.rally" :channel="this.channel" v-on:perform="perform" />
    </div>
    <div v-if="isRallyLoaded && isChannelLoaded">
      <component  :is="channelComponent" :key="channel.attributes.slug" :rally="rally" :channel="channel" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import RallyReloadMixin from "../components/RallyReloadMixin";
import RallyPaginationMixin from "../components/RallyPaginationMixin";
import ContentMixin from "@/components/ContentMixin.vue";
import ContentReloadMixin from "@/components/ContentReloadMixin.vue";
export default {
  data() {
    return {
      channel: null,
      rally: null,
      isRallyLoaded: false,
      isChannelLoaded: false
    }
  },
  computed: {
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    channelComponent() {
      switch (this.channel.attributes.type) {
        case 'Calendar':
          if(this.$route.params.id) {
            return () => import('./StandardContentShow.vue');
          } else {
            return () => import('./CalendarPage.vue');
          }
          // Add cases for other types as needed
        case 'Members':
          return () => import('./MembersPage.vue');
        case 'Directory':
          if(this.$route.params.id) {
            return () => import('./MemberDetailsPage.vue');
          } else {
            return () => import('./MembersPage.vue');
          }
        case 'Rallies':
          return () => import('./ChildRalliesPage.vue');
        default:
          "DEFAULT TO STANDARD CONTENT INDEX"
          if(this.$route.params.id) {
            return () => import('./StandardContentShow.vue');
          } else {
            return () => import('./StandardContentIndex.vue');
          }
      }
    },
    selectedRally() {
      return this.allRalliesByRallyId[this.rallyId];
    },
    back() {
      return ""
    },
    rallyId() {
      return this.$route.params.rallyId;
    },
    channelSlug() {
      return this.$route.params.channelSlug;
    },
  },
  watch: {
    async '$route'(to, from) {
      this.isChannelLoaded = false;
      if (to.params.channelSlug !== from.params.channelSlug) {
        await this.setChannel();
      }
      if (to.params.rallyId !== from.params.rallyId) {
        this.isRallyLoaded = false;
        await this.setRallyAndChannel();
      }
    },
    '$route.query': {
      async handler(query) {
        await this.setChannel();
      },
    },
    rally(newValue, oldValue) {
      if (newValue) {
        this.verifyAccess();
      }
    }
  },
  created() {
    this.isChannelLoaded = false;
    this.setRallyAndChannel();
  },
  methods: {
    ...mapActions("rallies", ["clearRallyState", "fetchMyRalliesWithInfo", "fetchRalliesChannels"]),
    ...mapActions("channels", ["fetchChannels"]),
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async setChannel() {
      await this.fetchRalliesChannels({ rallyId: this.rallyId });
      if (this.$route.params.channelSlug) {
        this.channel = this.rallyChannelsByRallyId[this.rallyId].find(channel => {
          // Types for which to append the query string
          const typesWithQueryString = ['Payables', 'GivePayableGroup', 'PayableGroup'];
          // Check if the channel's type is one of the specified types
          if (typesWithQueryString.includes(channel.attributes.type) && this.getQueryString()) {
            // Compare slug with channelSlug + query string
            return this.compareUrlsExcludingExtraParams(channel.attributes.slug, this.channelSlug + this.getQueryString());
            //return channel.attributes.slug === (this.channelSlug + this.getQueryString());
          } else {
            // For all other types, compare slug with channelSlug only
            const channelSlugBase = channel.attributes.slug.split('?')[0];

            return channelSlugBase === this.channelSlug;
          }
        });
      } else {
        //await this.delay(5000); 
        await this.fetchRalliesChannels({ rallyId: this.rallyId });
        let channels = await this.rallyChannelsByRallyId[parseInt(this.rallyId)]
        const viewableChannel = channels?.find(c => c?.attributes && (c?.attributes?.viewable || c?.attributes?.writable));
        if (viewableChannel) {
          router.push({ path: `/${this.rallyId}/${viewableChannel.attributes.slug}` });
        } else {
         console.log("NO CHANNEL TO REDIRECT TO, RENDERING DEFAULT");
         await this.delay(3000)
         //router.push({ path: `/rallies` })
         router.push({ path: `/${this.rallyId}/gate` });
        }
      }
      this.isChannelLoaded = true;
    },
    async setRally() {
      console.log("SETTINGRALLY")
      await this.fetchMyRalliesWithInfo({rallyId: this.rallyId})
      this.rally = this.allRalliesByRallyId[this.rallyId].rally;
      console.log("my rallly...")
      console.log(this.rally)
      if(this.rally && this.rally.attributes && this.rally.attributes.viewable !== true) {
        console.log("gate...")
        //delay because of rmems cache...although its faster to refresh now on the rmems side
        await this.delay(3000)
        router.push({ path: `/${this.rallyId}/gate` });
      }
      this.isRallyLoaded = true;
    },
    async setRallyAndChannel() {
      await this.setRally()
      await this.setChannel()
    },
    getQueryString() {
      const query = this.$route.query;
      const parts = [];
      for (const key in query) {
        if (Object.prototype.hasOwnProperty.call(query, key)) {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`);
        }
      }
      return parts.length ? `?${parts.join('&')}` : '';
    },
    perform(action) {
      switch (action) {
        case "reload":
          this.setChannel();
          break;
        case "back":
          router.push(this.back);
          break;
        default:
          console.log("Unknown action: " + action);
      }
    },
    async verifyAccess(){
      if (this.rally && this.rally.attributes && this.rally.attributes.viewable !== true) {
        //router.push({ path: '/rallies' });
        await this.delay(3000)
        router.push({ path: `/${this.rallyId}/gate` });
      }
    },
    compareUrlsExcludingExtraParams(str1, str2) {
      // Find the position of the '%' character in both strings
      const index1 = str1.indexOf('%');
      const index2 = str2.indexOf('%');

      // Extract substrings from both strings up to the position of '%'
      const substr1 = index1 !== -1 ? str1.substring(0, index1) : str1;
      const substr2 = index2 !== -1 ? str2.substring(0, index2) : str2;

      // Compare the substrings
      return substr1 === substr2;
    }
  }
};
</script>
