<template>
    <div class="actions" v-click-outside="hideActions">
        <Sentiments
            v-show="!hideSentiments"
            :message-id="content.id"
            :likeOnly=false
            :shareId="shareId"
        />
      <!--<ShareIcon v-if="!hideShareComputed" :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId" :contentType="this.contentType"/>-->
      <i
          v-show="!hideElipsis"
        @click="showElipsis = !showElipsis"
        class="action ellipsis-button more fa-ellipsis-h fas"
      />
      <div class="action ellipsis-button more " >
        <ul v-if="showElipsis" class="additional">
          <li v-if="canEditComputed" @click="emitEditEvent" class="option">
            <i class="fal fa-pencil"></i>Edit
          </li>
          <li v-if="canManage" @click="emitManageEvent" class="option">
            <i class="fal fa-wrench"></i>Manage
          </li>
          <li class="option">
            <DetailsIcon :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId"/>
          </li>
          <li v-if="isPinned && canPin && (canEditComputed || isAdmin)" class="option" @click="unPinContent">
            <i class="fal fa-thumbtack" aria-hidden="true"></i>Un-Pin From Top
          </li>
          <li v-if="!isPinned && canPin && (canEditComputed || isAdmin)" class="option" @click="pinContent">
            <i class="fas fa-thumbtack"></i>Pin To Top
          </li>
          <li v-if="canMove && canEditComputed" class="option" @click="move" >
            <i class="fal fa-folders"></i>Move
          </li>
          <li v-if="canShare" class="option" >
          <ShareIcon menuMode = "true" v-if="!hideShareComputed"
                     :content="content"
                     :rallyId="this.rallyId"
                     :channelId="this.channelId"
                     :contentType="this.contentType" />
          </li>
          <li v-if="canAssign" class="option">
            <EventParticipation
                :shareId="this.shareId"
                :content="this.content"
                :channelId="this.channelId"
                :assigning="true"
                :rally-id="this.rallyId"
                hide-button="true"
            />
          </li>
          <li v-if="canPromote" class="option">
            <PromoteIcon :content="content" :rallyId="this.rallyId" :channelId="this.channelId"></PromoteIcon>
          </li>
          <content-flagging  v-if="!hideReportComputed" :content="content" :rallyId="rallyId" showLabel="true"/>
           <li v-if="canDeleteComputed && !hideDeleteComputed" class="option">
            <DeleteIcon  :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId" menu="true"/>
          </li>
          <li v-if="canUnshareComputed" class="option">
            <UnshareIcon :content="content"  :rallyId="this.rallyId"
                         :channelId="this.channelId"
                         :shareId="this.shareId"
                         menu="true"/>
          </li>
          <li v-if="canDownload" @click="download" class="option">
            <i class="fal fa-file-download"></i>Download
          </li>
        </ul>
      </div>
      <OpenRhWebSpecialEvents :content="content" :uniqueId="this.openRhWebUniqueId"/>
      <OpenRhWebPayments :content="content" :uniqueId="this.openRhWebUniqueId"/>
      <OpenRhWebGives :content="content" :uniqueId="this.openRhWebUniqueId"/>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import {openRhWebBrowser, downloadFile} from "@/common/utils";
import UserPermissionMixin from './UserPermissionMixin.vue';
import BinaryMixin from "@/components/BinaryMixin.vue";
import ContentFlagging from "./ContentFlagging";
  export default {
    components: {ContentFlagging},
    mixins: [BinaryMixin, UserPermissionMixin],
    props: ['content', 'rallyId', 'channelId', 'menu', 'type', 'contentType',
      'hideDelete', 'hideReport', 'hideShare', 'hideElipsis', 'hideSentiments', 'canDownload', 'canShare',
      'canManage', 'canMove', 'canPin', 'canPromote', 'canAssign', 'openRhWebOnEdit'],
    data: function() {
      return {
        showElipsis: false
      };
    },
    created () {

    },
    computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("shares", ["sharesByContentId"]),
    /*userIsOwner(){
      return (this.content && this.content.attributes && this.content.attributes["creator-id"] == this.user.user_id) 
    },*/
    hideDeleteComputed(){
      return this.hideDelete
    },
    hideReportComputed(){
      return this.hideReport
    },
    hideShareComputed(){
      return this.hideShare
    },
    creatorId() {
      return this.content.attributes['creator-id']
    },
    isEditable() {
      return this.content.attributes['editable'] == 'true'
    },
    isPinned() {
      if (parseInt(this.content.attributes['sort-order']) === 0) {
        return true;
      }
      return false;
    },
    openRhWebUniqueId(){
      return this.content.id
    },
    rallyIdForUserAvatar(){
      if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
        return parseInt(this.ownerShare?.attributes['context-rally-id'])
      } else {
        return parseInt(this.rallyId)
      }
    },
      shareId() {
        return this.content.relationships['context-share'].data.id
      }
    },
    methods: {
      ...mapActions("contents", ["sortContent"]),
      openRhWeb(id, mode) {
        try{
          openRhWebBrowser(id, this.$route.params.rallyId, mode)
        } catch (e) {
          console.log(e)
        }
      },
      download() {
        downloadFile(this.content.attributes['url'], this.content.attributes['attachment-file-name']);
        /* trigger local notification for testing!*/
        /*setTimeout( function() {
                  let not = {
              notifications: [
                {
                  id: Date.now(),
                  body: "body",
                  title: "title",
                  extra: {url: "/126297/messages/"},
                  ongoing: false
                }
              ]
            };
            console.log("schedule local notifications!");
            const result = LocalNotifications.schedule(not);
            }.bind(this), 5000);*/
      },
      emitAssignEvent() {
        this.hideActions()
      },
      emitEditEvent() {
        if(this.openRhWebOnEdit) {
            this.openRhWeb(this.content.id, "edit")

        } else {
          eventBus.$emit('editContent', this.content.id)
        }
      },
      emitManageEvent() {
        console.log("emitmanage " +  this.content.id)
        if(this.openRhWebOnEdit) { 
          this.openRhWeb(this.content.id, "manage")
        }
      },
      hideActions() {
        this.showElipsis = false;
      },
      move() {
        this.$emit('moveFile');
      },
      pinContent(){
        this.sortContent({id: this.shareId, rallyId: this.rallyId, sortOrder: 0})
        this.hideActions()
        this.content.attributes['sort-order'] = 0
        this.resortArray()
      },
      unPinContent(){
        this.sortContent({id: this.shareId, rallyId: this.rallyId, sortOrder: 100})
        this.hideActions()
        this.content.attributes['sort-order'] = 100
        this.resortArray()
      },
      resortArray() {
        // Sort the array first by sort_order and then by created_at
        this.allContentByContextTypeAndId[this.channelId].sort((a, b) => {
          if (a.attributes['sort-order'] !== b.attributes['sort-order']) {
            return a.attributes['sort-order'] - b.attributes['sort-order'];
          } else {
            // If sort_order is the same, sort by created_at
            return new Date(a.attributes['created-at']) - new Date(b.attributes['created-at']);
          }
        });
      },
    }
  }
</script>
