<template>
  <div id="app" v-if="checkReloadData && !showMarketingSite">
    <AlertArea v-if="alert" />
    <DarkOverlay ref="darkOverlay"/>
    <!--<div class="rh-safe-area-nav-top"/>-->
    <nav class="app-nav">
      <img @click="backToDashboard" src="/rallyhood.svg" class="rh-logo" />
      <img v-if="user" @click="backToDashboard" id="rallyhood-nav" src="/rallyhood-icon.svg" />
      <i v-show="(this.pageTitle == 'My Rallyhood') && checkIsMobile"
        class="fa fa-arrows app-arrows" 
        :class="{ selected: this.moveRallies }" 
        @click="toggleSelected"
        aria-hidden="true"> <label class="move-rallies-label" v-show="moveRallies">&nbsp; Move Rallies</label>
      </i>
      <BellNotification v-if="user" ref="rhNotifications" v-on:toggle-bell="toggleBell" />
      <UserMenu v-if="user" />
      <h1 class="title" @click="toggleRallyInfo">
        {{ pageTitle }}
      </h1>
    </nav>
    <router-view></router-view>
  </div>
  <div v-else>
    <MarketingSiteProxy/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { router, timezoneMapping, railsTzToMomentTz, isMobile} from "../common";
import AlertArea from "../components/AlertArea";
import BellNotification from "../components/BellNotification";
import DarkOverlay from "../components/DarkOverlay.vue";
import { userInfo } from "../common/user-info";
import moment from "moment-timezone";
import { eventBus } from "../main.js";
import MarketingSiteProxy from "@/components/MarketingSiteProxy.vue";
import { Capacitor } from "@capacitor/core";

export default {
  name: "app",
 /* mixins: [
    ReloadMixin
  ],*/
  data() {
    return {
      bellNotifications: false,
    };
  },
  components: {
    AlertArea ,
    BellNotification,
    DarkOverlay,
    MarketingSiteProxy
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("rallies", ["allRalliesByRallyId", "moveRallies"]),
    checkIsMobile(){
      return isMobile()
    },
    alert() {
      return this.$store.getters['user/alert'].message;
    },
    pageTitle: function() {
      if ( this.bellNotifications ) {
        return 'Notifications';
      }

      let rid = this.$route?.params?.rallyId ? this.$route.params.rallyId : ""
      let rallyTitle = rid && this.allRalliesByRallyId[rid] ?  this.allRalliesByRallyId[rid]?.rally?.attributes?.title : ""

      if ( rallyTitle && rallyTitle.length > 20 ) {
        rallyTitle = rallyTitle.slice(0, 20) + '...';
      }

      return rallyTitle || this.$route.meta.title;
    },
    checkReloadData() {
      const exemptPages = ["/resetpassword", "/resetpasswordemail", "/register", "/createAccount", "/igate"];
      const isExemptPage = exemptPages.some(page => this.$route.path.includes(page));
    if (!this.showMarketingSite && !isExemptPage && (!userInfo() || !userInfo().user_id) ) {
        router.push({
          path: '/login',
          query: { ...this.$route.query }
        });
        return true
      }
      else if (!this.showMarketingSite && !isExemptPage && !this.usersById[parseInt(userInfo().user_id)]) {
        this.loadPageData();
        return false;
      } else if (!this.showMarketingSite && !isExemptPage) {
          if (railsTzToMomentTz(userInfo().timezone)){
            moment.tz.setDefault(railsTzToMomentTz(userInfo().timezone))
          }
      }
      return true;
    },
    showMarketingSite() { 
      if ((Capacitor.getPlatform() == "android" || Capacitor.getPlatform() == "ios")){
        return false
      } else {
        return (this.$route.path === '/' || this.$route.path === '/home' || this.$route.path.includes('/home/')) && !userInfo();
      }
    },
  },
  created(){
      const platformClass = Capacitor.getPlatform(); 
      document.body.classList.add(platformClass);
      eventBus.$on('overlayOn' , (p) => {
        this.$refs.darkOverlay.overlayOn();
      })
      eventBus.$on('overlayOff' , (p) => {
        this.$refs.darkOverlay.overlayOff();
      })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear"
    }),
    ...mapActions("user", ["fetchUsersById", "reloadUserAfterRefresh"]),
    ...mapActions("rallies", ["toggleRallyInfo", "toggleMoveRallies"]),
    toggleSelected() {
      //this.isSelected = !this.isSelected;
      this.toggleMoveRallies()
    },
    backToDashboard() {
      this.$router.push("/rallies");
    },
    /*toggleOverlay() {
      console.log("this.$refs")
      console.log(this.$refs)
      this.$refs.darkOverlay.toggleOverlay();
    },*/
    loadPageData() {
      try {
        if (userInfo() && userInfo().user_id) {
          this.reloadUserAfterRefresh()
          if (!this.usersById[parseInt(userInfo().user_id)]) {
            var rallyObjs = [];
            rallyObjs[this.rallyId] = { id: this.rallyId };
              this.fetchUsersById({
                userIds: [userInfo().user_id],
                loggedInUser: true
              });
          } else {
            router.push(`/login`);
          }
        }
      } catch (e) {
        console.log("load page data error occured...")
        console.log(e);
      }
    },
    toggleBell(newState) {
      this.bellNotifications = newState;
    },
  }
  /*watch: {
        $route (to, from){
            // clear alert on location change
            this.clearAlert();
        }
    } */
};
</script>

<style lang="scss">
@import "../stylesheets/main.scss";
@media (max-width: 960px) {
  .app-arrows {
    color: black; /* Default color */
    cursor: pointer;
    transition: color 0.3s;
    left: 55px;
    top:60px;
    position: absolute;
  }
}

@media (min-width: 960px) {
  .app-arrows {
    display:none;
  }
}

.fa-arrows.selected {
  color: #1867c0; /* Selected color */
}

.move-rallies-label {
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
</style>
