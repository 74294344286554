import axios from "axios";

import {
    buildServiceParamMap,
    RCS_SHARE_URL
} from "@/common";
import { userInfo } from "@/common/user-info"
import jwtInterceptor  from '../../common/jwt-interceptor';

const actions = {
    async fetchSharesByContentId({commit, state}, inparams) {
        const {contentId, contextRallyId, contextChannelId} = inparams;
        const user = userInfo();
        var jsonparams = {
            //content_id: contentId,
            content_uid: contentId,
            // context_rally_id: contextRallyId,
            // context_channel_id: contextChannelId,
            "page[number]": "1",
            "page[size]": "200",
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RCS_SHARE_URL(),
            params
        };
        const response = await jwtInterceptor(requestOptions);
        //const enrichedData = enrichDataWithIncluded(response)

        return response.data.data;
    },
}

export default {
    namespaced: true, // Make this module namespaced
    actions,
};