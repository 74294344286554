import axios from "axios";

import {
    buildServiceParamMap,
    RCHANS_URL,
} from "@/common";
import { userInfo } from "@/common/user-info"
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {

};

const mutations = {

};

const getters = {
};

const actions = {
    async fetchChannels({commit, state}, inParams){
        const {rallyIds, type = null, sort = 'sort_order', channel_state = 'on', default_channels = true, pageNumber = 1, pageSize = 6, include = ''}  = inParams
        const user = userInfo();

        try {
            let jsonParams = {
                rally_id: rallyIds,
                default: default_channels,
                state: channel_state,
                "ne[type]": ['RallySettings'],
                include: include,
                "page[number]": pageNumber,
                "page[size]": pageSize,
                sort: sort
            };

            if (type != null) {
                jsonParams.type = type;
            }
            let params = buildServiceParamMap(jsonParams);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                url: RCHANS_URL(), //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
                params
            };

            const response = await jwtInterceptor(requestOptions);

            return response.data.data;
        } catch (error) {
            console.error('Error fetching rallies:', error);
            throw error; // Rethrow for further handling
        }
    },
};

export default {
    namespaced: true, // Make this module namespaced
    state,
    mutations,
    getters,
    actions,
};