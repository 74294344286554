import axios from "axios";

import {
    assembleRallyObject,
    buildServiceParamMap, enrichDataWithIncluded,
    figureOutPageNumber,
    RRS_URL,
} from "@/common";
import { userInfo } from "@/common/user-info"
import jwtInterceptor  from '../../common/jwt-interceptor';

let lastFetchTimestamp = null; // To track the last fetch time
const managedRelationships = ['FriendlyParentChild', 'ParentChild', 'Managed', 'Open', 'HiddenOrg', 'Associated', 'ReadOnlyParentChild'];
const state = {
    rallies: [], // State to hold the rallies
    ralliesByParent: {}, // State to hold the rallies by parent
};

const mutations = {
    setRallies(state, payload) {
        state.rallies = payload;
    },
};

const getters = {
    getRallies: (state) => state.rallies,
};

const actions = {
    async fetchRallies({commit, state }, inParams){
        const {rallyIds, sort = 'archived, title', status = 'Active', pageNumber = 1, pageSize = 6, include = ''}  = inParams
        const user = userInfo();

        try {
            let jsonParams = {
                id: rallyIds,
                include: include,
                "page[number]": pageNumber,
                "page[size]": pageSize,
                sort: sort,
                status: status
            };

            let params = buildServiceParamMap(jsonParams);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                url: RRS_URL() +"/rallies" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
                params
            };

            const response = await jwtInterceptor(requestOptions);
            //const enrichedData = enrichDataWithIncluded(response)

            return response.data.data;
        } catch (error) {
            console.error('Error fetching rallies:', error);
            throw error; // Rethrow for further handling
        }
    },
    async fetchRallyRelationshipsByParentId({commit, state }, inParams){
        const {rallyId, sort = 'archived, title', status = 'Active', pageNumber = 1, pageSize = 6, include = ''}  = inParams
        const user = userInfo();

        try {
            let jsonParams = {
                left_rally_id: rallyId,
                type: managedRelationships,
                include: include,
                "page[number]": pageNumber,
                "page[size]": pageSize,
                sort: sort,
                status: status
            };

            let params = buildServiceParamMap(jsonParams);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                url: RRS_URL() +"/rally-relationships" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
                params
            };

            const response = await jwtInterceptor(requestOptions);
            const enrichedData = enrichDataWithIncluded(response)

            return enrichedData;
        } catch (error) {
            console.error('Error fetching rallies:', error);
            throw error; // Rethrow for further handling
        }
    },

    async fetchRalliesByParentId({commit, state }, inParams){
        const {rallyId, sort = 'archived, title', status = 'Active', pageNumber = 1, pageSize = 6, include = ''}  = inParams
        const user = userInfo();

        try {
            let jsonParams = {
                a_relation_id: rallyId,
                relationship_type: managedRelationships,
                include: include,
                "page[number]": pageNumber,
                "page[size]": pageSize,
                sort: sort,
                status: status
            };

            let params = buildServiceParamMap(jsonParams);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                url: RRS_URL() +"/rallies" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
                params
            };

            const response = await jwtInterceptor(requestOptions);
            //const enrichedData = enrichDataWithIncluded(response)

            return response.data.data;
        } catch (error) {
            console.error('Error fetching rallies:', error);
            throw error; // Rethrow for further handling
        }
    },
    async fetchRallyRelationshipsByCategory({commit, state }, inparams){
        const { categoryId, rallyRelationshipIds }  = inparams
        const user = userInfo();
        var jsonparams = {
            id: rallyRelationshipIds,
            "page[number]": 1,
            "page[size]": "500"
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RRS_URL() +"/rally-relationships" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
            params
        };
        const response = await jwtInterceptor(requestOptions);
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            return response.data.data;
        }
    },
};

export default {
    namespaced: true, // Make this module namespaced
    state,
    mutations,
    getters,
    actions,
};