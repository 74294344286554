<template>
  <div>
    <main class="gate reset">
      <form @submit.prevent="handleSubmit" ref="passwordResetForm">
        <h2>
          <img alt="Rallyhood Logo" class="logo" src="rallyhood.svg" /> 
          Reset Password
        </h2>
        <div  v-if="!passwordReset">
          <div class="form-group">
            <label for="email">Email Address</label>
            <input v-model="email" name="email" type="text" />
          </div>
          <div class="actions">
            <button class="primary" type="submit">
              Reset Password Email
            </button>
          </div>
          <hr />
          <div class="trouble">
            <div>
              <router-link class="link-button" to="/login">Return to Login</router-link>
              or
              <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
            </div>
          </div>
        </div>
        <div v-if="passwordReset">
          We'll email you a link to change your password.

          You're almost done!

          Please check your email for instructions on how to reset your password.

          It may take up to 10 minutes to show up in your mailbox.
          <br/>
          <hr>
          <div class="trouble">
            <router-link class="link-button" to="/login">Return to Login</router-link>
            or
            <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
          </div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        passwordReset: false
      };
    },
    methods: {
      ...mapActions("user", ["resetPasswordEmail"]),
      handleSubmit(e) {
        const { email } = this;
        if (email) {
          this.resetPasswordEmail({email});
          this.passwordReset = true;
        }
      }
    }
  };

</script>
